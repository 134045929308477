import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import * as moment from 'moment'
import showdown from 'showdown'

const BlogPost = ({ data }) => {
  const post = data.graphAPI.blogPost
  const converter = new showdown.Converter({
      strikethrough: true
  })
  return (
    <Layout>
      <SEO title={post.title} />
      <div className="container anchor">
        <div className="columns">
          <div className="column is-8 is-offset-1 inset">
            <h1 className="is-size-2" style={{ marginTop: 0}}>{post.title}</h1>
            <p>Posted: {moment(post.publishedAt).format("MMMM Do, YYYY")}</p>
            {/* <div dangerouslySetInnerHTML={{__html: post.content}}></div> */}
            {post.content.map(block => {
              switch (block.__typename) {
                case 'GRAPHAPI_ComponentPageImage':
                  const alignLeft = block.placement === 'left'
                  const alignCenter = block.placement === 'center'
                  const alignRight = block.placement === 'right'
                  return (<figure
                            style={Object.assign({ width: block.width, 
                              height: 'auto',
                              margin: alignCenter ? '1rem auto':'1rem',
                              }, alignLeft ? {float: 'left'}: {}, 
                              alignRight ? {float: 'right'}: {}
                            )}
                          >
                          <Img fluid={block.image.documentFile.childImageSharp.fluid} 
                            alt={block.image.alternativeText}
                          />
                        </figure>
                    )
                  break
                case 'GRAPHAPI_ComponentPageBaseContent':
                  let body = converter.makeHtml(block.body)
                  return (<div class="content" dangerouslySetInnerHTML={{__html: body}}></div>)
                  break
                default:
                  return (<div class="content" dangerouslySetInnerHTML={{__html: block.__typename}}></div>)
              }
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: ID!) {
    graphAPI {
      blogPost(id: $id) {
        id
        title
        publishedAt
        description
        postType
        websiteLink
        document {
          url
          created_at
          updated_at
          documentFile {
            publicURL
          }
        }
        content {
          __typename
          ... on GRAPHAPI_ComponentPageBaseContent {
            body
          }
          ... on GRAPHAPI_ComponentPageImage {
            id
            width
            placement
            image {
              created_at
              updated_at
              url
              alternativeText
              documentFile {
                childImageSharp {
                  fluid(maxWidth: 2000){
                    ...GatsbyImageSharpFluid_tracedSVG
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
